import React from 'react';
import { navigate, PageProps } from 'gatsby';
import { Translate } from 'src/components/Translate';
import { useTimeoutFn } from 'react-use';
import useRedirect from 'src/hooks/useRedirect';
import { shouldRedirectTo } from 'src/hooks/usePreventMemberCheckout';
import { useAuthContext } from 'src/components/AuthProvider';
import useMembership from 'src/api/useMembership';
import useProfile from 'src/api/useProfile';
import { SimpleFooter } from 'src/features/footer';

const i18n = {
  heading: 'pages.legacyMembership.heading',
  description: 'pages.legacyMembership.description',
  confirm: 'pages.legacyMembership.confirm',
};

type AutoLinkProps = {
  to: string;
  children: JSX.Element;
  [x: string]: any;
};

const AutoLink: React.FC<AutoLinkProps> = ({ children, to, ...x }) => {
  let external = null;
  if (to) {
    external = to.match(/^https?:/);
  } else {
    // redirect users who navigated directly
    const { isAuthenticated, loading: authLoading } = useAuthContext();
    const { data: membership, loading: membershipLoading, hasLegacyMembership } = useMembership();
    const { loading: profileLoading } = useProfile();
    const loading = authLoading || membershipLoading || profileLoading;
    const member = membership?.hasMembership();
    const vip = membership?.isVip();
    const toUrl = shouldRedirectTo(hasLegacyMembership, member, vip);
    loading || !isAuthenticated || !toUrl
      ? navigate('/checkout/')
      : navigate(shouldRedirectTo(hasLegacyMembership, member, vip));
  }

  const handleRedirect = () => {
    if (external) {
      window.location.href = to;
    } else {
      navigate(to);
    }
  };

  useTimeoutFn(handleRedirect, 5000);

  if (external) {
    return (
      <a href={to} {...x}>
        {children}
      </a>
    );
  }
  return (
    <button onClick={handleRedirect} {...x}>
      {children}
    </button>
  );
};

const CheckoutRedirect: React.FC<PageProps> = ({ location }) => {
  const targetURL: string = location?.state?.['to'];

  useRedirect(targetURL);

  return (
    <>
      <div className="container container_center page__checkout-redirect">
        <h2 className="text-display_2">
          <Translate resourceKey={i18n.heading} />
        </h2>
        <Translate as="p" resourceKey={i18n.description} />
        <div className="inset-l" />
        <div className="cta_button-group button-group top-padding-xl">
          <div className="button-group__item">
            <AutoLink
              to={targetURL}
              data-cy="confirm-checkout-redirect"
              className="button button_primary button_primary--large"
            >
              <Translate resourceKey={i18n.confirm} />
            </AutoLink>
          </div>
        </div>
      </div>

      <div className="inset-l" />

      <SimpleFooter />
    </>
  );
};

export default CheckoutRedirect;
